@charset "UTF-8";

:root {
  /* Colors primary btn */
  --bs-btn-primary-color: #ffffff;
  --bs-btn-primary-bg: #6a36ea;
  --bs-btn-primary-border-color: #6a36ea;
  --bs-btn-primary-hover-color: #ffffff;
  --bs-btn-primary-hover-bg: #6a36eab3;
  --bs-btn-primary-hover-border-color: #6a36eab3;
  --bs-btn-primary-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-primary-active-color: #000000;
  --bs-btn-primary-active-bg: #6a36eab3;
  --bs-btn-primary-active-border-color: #6a36eab3;
  --bs-btn-primary-active-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  --bs-btn-primary-disabled-color: #000000;
  --bs-btn-primary-disabled-bg: #6a36ea;

  /* Color secondary btn */
  --bs-btn-secondary-color: #ffffff;
  --bs-btn-secondary-bg: #000000;
  --bs-btn-secondary-border-color: #000000;
  --bs-btn-secondary-hover-color: #000000;
  --bs-btn-secondary-hover-bg: #ffffff;
  --bs-btn-secondary-hover-border-color: #141111;
  --bs-btn-secondary-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-secondary-active-color: #807f7e;
  --bs-btn-secondary-active-bg: #000000;
  --bs-btn-secondary-active-border-color: #706e6a;
  --bs-btn-secondary-active-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  --bs-btn-secondary-disabled-color: #000000;
  --bs-btn-secondary-disabled-bg: #020100;

  /* Color tertiary btn */
  --bs-btn-tertiary-color: #d1d1d1;
  --bs-btn-tertiary-bg: #ffffff;
  --bs-btn-tertiary-border-color: #000000;
  --bs-btn-tertiary-hover-color: #000000;
  --bs-btn-tertiary-hover-bg: #ffffff;
  --bs-btn-tertiary-hover-border-color: #ffffff;
  --bs-btn-tertiary-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-tertiary-active-color: #807f7e;
  --bs-btn-tertiary-active-bg: #ffffff;
  --bs-btn-tertiary-active-border-color: #706e6a;
  --bs-btn-tertiary-active-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  --bs-btn-tertiary-disabled-color: #000000;
  --bs-btn-tertiary-disabled-bg: #020100;

  /* Color dark btn */
  --bs-btn-dark-color: #ffffff;
  --bs-btn-dark-bg: #000000;
  --bs-btn-dark-border-color: #807f7e;
  --bs-btn-dark-hover-color: #000000;
  --bs-btn-dark-hover-bg: #ffffff;
  --bs-btn-dark-hover-border-color: #141111;
  --bs-btn-dark-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-dark-active-color: #807f7e;
  --bs-btn-dark-active-bg: #000000;
  --bs-btn-dark-active-border-color: #706e6a;
  --bs-btn-dark-active-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  --bs-btn-dark-disabled-color: #000000;
  --bs-btn-dark-disabled-bg: #020100;

  /* Color danger btn */
  --bs-btn-danger-color: #ffffff;
  --bs-btn-danger-bg: #e94242;
  --bs-btn-danger-border-color: #e94242;
  --bs-btn-danger-hover-color: #ffffff;
  --bs-btn-danger-hover-bg: #980e26;
  --bs-btn-danger-hover-border-color: #980e26;
  --bs-btn-danger-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-danger-active-color: #ffffff;
  --bs-btn-danger-active-bg: #980e26;
  --bs-btn-danger-active-border-color: #980e26;
  --bs-btn-danger-active-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  --bs-btn-danger-disabled-color: #000000;
  --bs-btn-danger-disabled-bg: #cc0000;

  /* Color warning btn */
  --bs-btn-warning-color: #000000;
  --bs-btn-warning-bg: #ffdb2b;
  --bs-btn-warning-border-color: #ffdb2b;
  --bs-btn-warning-hover-color: #000000;
  --bs-btn-warning-hover-bg: #dbb81f;
  --bs-btn-warning-hover-border-color: #dbb81f;
  --bs-btn-warning-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-warning-active-color: #000000;
  --bs-btn-warning-active-bg: #dbb81f;
  --bs-btn-warning-active-border-color: #dbb81f;
  --bs-btn-warning-active-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  --bs-btn-warning-disabled-color: #000000;
  --bs-btn-warning-disabled-bg: #ffdb2b;

  /* Color success btn */
  --bs-btn-success-color: #ffffff;
  --bs-btn-success-bg: #47bc34;
  --bs-btn-success-border-color: #47bc34;
  --bs-btn-success-hover-color: #ffffff;
  --bs-btn-success-hover-bg: #5a9f1b;
  --bs-btn-success-hover-border-color: #5a9f1b;
  --bs-btn-success-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-success-active-color: #ffffff;
  --bs-btn-success-active-bg: #5a9f1b;
  --bs-btn-success-active-border-color: #5a9f1b;
  --bs-btn-success-active-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  --bs-btn-success-disabled-color: #ffffff;
  --bs-btn-success-disabled-bg: #47bc34;

  /* Colors text */
  --custom-text-white: #ffffff;
  --custom-text-dark: #000000;
  --custom-text-dark-gray: #1e3543;
  --custom-text-gray: #75797c;
  --custom-text-danger: #e94242;
  --custom-text-netwrok-public: #74c0fc;
  --custom-text-netwrok-private: #878787;

  /* Colors background */
  --bg-custom-primary: #6a36ea;
  --bg-custom-secondary: #000000;
  --bg-custom-warning: #ffdb2b;
  --bg-custom-success: #47bc34;
  --bg-custom-danger: #e94242;
  --bg-custom-white: #ffffff;
  --bg-custom-gray: #d9d9d9;
  --bg-custom-orange: #ffa51e;
  --bg-custom-hr: #505050;
  --bg-custom-secondary-color: #ffc800;
}

.margin-body-app-open {
  padding-right: 0px !important;
  margin-left: 240px;
  transition: all 0.5s ease-in-out 0s;
}

.margin-body-app-close {
  padding-right: 0px !important;
  margin-left: 0px;
  transition: all 0.5s ease-in-out 0s;
}

/* estilos okrs*/
.bg-progress-bar {
  background-color: var(--bg-custom-gray);
}

progressbar.progress bar.progress-bar {
  background-color: var(--bg-custom-primary) !important;
  color: var(--custom-text-dark);
}

.bg-primario {
  background-color: var(--bg-custom-primary) !important;
}

.bg-segundario {
  background-color: var(--bg-custom-secondary-color) !important;
}

.text-dark {
  color: var(--custom-text-dark) !important;
}

.text-secondary {
  color: var(--bg-custom-secondary-color) !important;
}

.text-primario {
  color: var(--bg-custom-primary) !important;
}

.centered-modal-container {
  opacity: 1 !important;
  align-items: center;
  justify-content: center;
  display: flex !important;
}

/* Estilos login */
.container-app {
  height: 100vh;
  background-color: var(--bg-custom-secondary);
  padding: 50px 80px 70px 80px;
  width: 100vw;
}

input.form-control {
  border-radius: 30px;
  height: 30px;
}

.line-login-form {
  height: 1px;
  background-color: var(--bg-custom-secondary);
}

.input-icon {
  position: relative;
}

.background-warning {
  background-color: var(--bg-custom-warning);
}

.input-icon .fa-solid {
  position: absolute;
  padding: 10px;
  top: 17px;
  font-size: 18px;
  z-index: 100;
  right: 5px;
  cursor: pointer;
}

.spinner-border {
  color: var(--bg-custom-primary) !important;
}

.spinner-border-dark {
  color: var(--bg-custom-secondary) !important;
}

.forgot-pass {
  cursor: pointer;
}

.rounded-button {
  border-radius: 50px !important;
}

.width-form-login {
  width: 60%;
}

.form-registration {
  height: calc(100vh - 210px);
  overflow-y: auto;
}

.space-container {
  min-height: 100%;
  justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
  .width-form-login {
    width: 100%;
  }
}

/* Estilos btn */
.btn {
  font-family: "Poppins", "Arial", "sans-serif";
}

/* Estilos btn primary */
.btn.btn-primario {
  background: var(--bs-btn-primary-bg);
  border-color: var(--bs-btn-primary-border-color);
  color: var(--bs-btn-primary-color);
  height: 35px;
  padding: 5px 10px;
}

/* Estilos botones plataforma */
.btn.btn-primario.disabled,
.btn.btn-primario[disabled],
.btn.btn-primario.disabled:hover,
.btn.btn-primario[disabled]:hover,
.btn.btn-primario.disabled:focus,
.btn.btn-primario[disabled]:focus,
.btn.btn-primario.disabled.focus,
.btn.btn-primario[disabled].focus,
.btn.btn-primario.disabled:active,
.btn.btn-primario[disabled]:active,
.btn.btn-primario.disabled.active,
.btn.btn-primario[disabled].active {
  background: var(--bs-btn-primary-disabled-bg);
  border-color: var(--bs-btn-primary-disabled-bg);
}

.btn.btn-primario.disabled:hover,
.btn.btn-primario[disabled]:hover,
.btn.btn-primario.disabled:hover:hover,
.btn.btn-primario[disabled]:hover:hover,
.btn.btn-primario.disabled:focus:hover,
.btn.btn-primario[disabled]:focus:hover,
.btn.btn-primario.disabled.focus:hover,
.btn.btn-primario[disabled].focus:hover,
.btn.btn-primario.disabled:active:hover,
.btn.btn-primario[disabled]:active:hover,
.btn.btn-primario.disabled.active:hover,
.btn.btn-primario[disabled].active:hover {
  -webkit-transform: none;
  transform: none;
}

.btn.btn-primario:hover,
.btn.btn-primario:focus,
.btn.btn-primario.focus,
.btn.btn-primario:active,
.btn.btn-primario.active,
.btn.btn-primario:target,
.btn.btn-primario.ng-binding.active,
.btn.btn-primario.ng-binding:active,
.btn.btn-primario.ng-binding.focus,
.btn.btn-primario.ng-binding:focus,
.btn-primario:not(:disabled):not(.disabled).active,
.btn-primario:not(:disabled):not(.disabled):active,
.show > .btn-primario.dropdown-toggle,
a.btn.btn-primario.ng-binding {
  background-color: var(--bs-btn-primary-bg);
  border-color: var(--bs-btn-primary-active-border-color);
}

/* Estilos btn secondary */
.btn.btn-segundario {
  color: var(--bs-btn-secondary-color);
  background-color: var(--bs-btn-secondary-bg);
  border-color: var(--bs-btn-secondary-color);
  height: 35px;
  padding: 5px 10px;
}

.btn.btn-segundario.disabled,
.btn.btn-segundario[disabled],
.btn.btn-segundario.disabled:hover,
.btn.btn-segundario[disabled]:hover,
.btn.btn-segundario.disabled:focus,
.btn.btn-segundario[disabled]:focus,
.btn.btn-segundario.disabled.focus,
.btn.btn-segundario[disabled].focus,
.btn.btn-segundario.disabled:active,
.btn.btn-segundario[disabled]:active,
.btn.btn-segundario.disabled.active,
.btn.btn-segundario[disabled].active {
  background: var(--bs-btn-secondary-hover-bg);
  border-color: var(--bs-btn-secondary-hover-border-color);
  color: var(--bs-btn-secondary-hover-color);
}

.btn.btn-segundario.disabled:hover,
.btn.btn-segundario[disabled]:hover,
.btn.btn-segundario.disabled:hover:hover,
.btn.btn-segundario[disabled]:hover:hover,
.btn.btn-segundario.disabled:focus:hover,
.btn.btn-segundario[disabled]:focus:hover,
.btn.btn-segundario.disabled.focus:hover,
.btn.btn-segundario[disabled].focus:hover,
.btn.btn-segundario.disabled:active:hover,
.btn.btn-segundario[disabled]:active:hover,
.btn.btn-segundario.disabled.active:hover,
.btn.btn-segundario[disabled].active:hover {
  -webkit-transform: none;
  transform: none;
}

.btn.btn-segundario:hover,
.btn.btn-segundario:focus,
.btn.btn-segundario.focus,
.btn.btn-segundario:active,
.btn.btn-segundario.active,
.btn.btn-segundario:target {
  background: var(--bs-btn-secondary-hover-bg);
  border-color: var(--bs-btn-secondary-active-border-color);
  color: var(--bs-btn-secondary-hover-color);
}

/* Estilos btn tertiary */
.btn.btn-terciario {
  color: black;
  background: var(--bs-btn-tertiary-bg);
  border-color: var(--bs-btn-tertiary-color);
  height: 35px;
  padding: 5px 10px;
}

.btn.btn-terciario.disabled,
.btn.btn-terciario[disabled],
.btn.btn-terciario.disabled:hover,
.btn.btn-terciario[disabled]:hover,
.btn.btn-terciario.disabled:focus,
.btn.btn-terciario[disabled]:focus,
.btn.btn-terciario.disabled.focus,
.btn.btn-terciario[disabled].focus,
.btn.btn-terciario.disabled:active,
.btn.btn-terciario[disabled]:active,
.btn.btn-terciario.disabled.active,
.btn.btn-terciario[disabled].active {
  background: var(--bs-btn-tertiary-hover-bg);
  border-color: var(--bs-btn-tertiary-hover-border-color);
  color: var(--bs-btn-tertiary-hover-color);
}

.btn.btn-terciario.disabled:hover,
.btn.btn-terciario[disabled]:hover,
.btn.btn-terciario.disabled:hover:hover,
.btn.btn-terciario[disabled]:hover:hover,
.btn.btn-terciario.disabled:focus:hover,
.btn.btn-terciario[disabled]:focus:hover,
.btn.btn-terciario.disabled.focus:hover,
.btn.btn-terciario[disabled].focus:hover,
.btn.btn-terciario.disabled:active:hover,
.btn.btn-terciario[disabled]:active:hover,
.btn.btn-terciario.disabled.active:hover,
.btn.btn-terciario[disabled].active:hover {
  -webkit-transform: none;
  transform: none;
}

.btn.btn-terciario:hover,
.btn.btn-terciario:focus,
.btn.btn-terciario.focus,
.btn.btn-terciario:active,
.btn.btn-terciario.active,
.btn.btn-terciario:target {
  background: var(--bs-btn-tertiary-hover-bg);
  border-color: var(--bs-btn-tertiary-active-border-color);
  color: var(--bs-btn-tertiary-hover-color);
}

.btn.btn-link-account:hover {
  background: #ffc516;
  border-color: #ffc516 !important;
}

/* Estilos btn success */
.btn.btn-correcto {
  color: var(--bs-btn-success-color);
  background: var(--bs-btn-success-bg);
  border-color: var(--bs-btn-success-color);
  height: 35px;
  padding: 5px 10px;
}

.btn.btn-correcto.disabled,
.btn.btn-correcto[disabled],
.btn.btn-correcto.disabled:hover,
.btn.btn-correcto[disabled]:hover,
.btn.btn-correcto.disabled:focus,
.btn.btn-correcto[disabled]:focus,
.btn.btn-correcto.disabled.focus,
.btn.btn-correcto[disabled].focus,
.btn.btn-correcto.disabled:active,
.btn.btn-correcto[disabled]:active,
.btn.btn-correcto.disabled.active,
.btn.btn-correcto[disabled].active {
  background: var(--bs-btn-success-hover-bg);
  border-color: var(--bs-btn-success-hover-border-color);
  color: var(--bs-btn-success-hover-color);
}

.btn.btn-correcto.disabled:hover,
.btn.btn-correcto[disabled]:hover,
.btn.btn-correcto.disabled:hover:hover,
.btn.btn-correcto[disabled]:hover:hover,
.btn.btn-correcto.disabled:focus:hover,
.btn.btn-correcto[disabled]:focus:hover,
.btn.btn-correcto.disabled.focus:hover,
.btn.btn-correcto[disabled].focus:hover,
.btn.btn-correcto.disabled:active:hover,
.btn.btn-correcto[disabled]:active:hover,
.btn.btn-correcto.disabled.active:hover,
.btn.btn-correcto[disabled].active:hover {
  -webkit-transform: none;
  transform: none;
}

.btn.btn-correcto:hover,
.btn.btn-correcto:focus,
.btn.btn-correcto.focus,
.btn.btn-correcto:active,
.btn.btn-correcto.active,
.btn.btn-correcto:target {
  background: var(--bs-btn-success-hover-bg);
  border-color: var(--bs-btn-success-active-border-color);
  color: var(--bs-btn-success-hover-color);
}

/* Estilos btn warning */
.btn.btn-precaucion {
  color: var(--bs-btn-warning-color);
  background: var(--bs-btn-warning-bg);
  border-color: var(--bs-btn-warning-color);
  height: 35px;
  padding: 5px 10px;
}

.btn.btn-precaucion.disabled,
.btn.btn-precaucion[disabled],
.btn.btn-precaucion.disabled:hover,
.btn.btn-precaucion[disabled]:hover,
.btn.btn-precaucion.disabled:focus,
.btn.btn-precaucion[disabled]:focus,
.btn.btn-precaucion.disabled.focus,
.btn.btn-precaucion[disabled].focus,
.btn.btn-precaucion.disabled:active,
.btn.btn-precaucion[disabled]:active,
.btn.btn-precaucion.disabled.active,
.btn.btn-precaucion[disabled].active {
  background: var(--bs-btn-warning-hover-bg);
  border-color: var(--bs-btn-warning-hover-border-color);
  color: var(--bs-btn-warning-hover-color);
}

.btn.btn-precaucion.disabled:hover,
.btn.btn-precaucion[disabled]:hover,
.btn.btn-precaucion.disabled:hover:hover,
.btn.btn-precaucion[disabled]:hover:hover,
.btn.btn-precaucion.disabled:focus:hover,
.btn.btn-precaucion[disabled]:focus:hover,
.btn.btn-precaucion.disabled.focus:hover,
.btn.btn-precaucion[disabled].focus:hover,
.btn.btn-precaucion.disabled:active:hover,
.btn.btn-precaucion[disabled]:active:hover,
.btn.btn-precaucion.disabled.active:hover,
.btn.btn-precaucion[disabled].active:hover {
  -webkit-transform: none;
  transform: none;
}

.btn.btn-precaucion:hover,
.btn.btn-precaucion:focus,
.btn.btn-precaucion.focus,
.btn.btn-precaucion:active,
.btn.btn-precaucion.active,
.btn.btn-precaucion:target {
  background: var(--bs-btn-warning-hover-bg);
  border-color: var(--bs-btn-warning-active-border-color);
  color: var(--bs-btn-warning-hover-color);
}

/* Estilos btn danger */
.btn.btn-peligro {
  color: var(--bs-btn-danger-color);
  background: var(--bs-btn-danger-bg);
  border-color: var(--bs-btn-danger-color);
  height: 35px;
  padding: 5px 10px;
}

.btn.btn-peligro.disabled,
.btn.btn-peligro[disabled],
.btn.btn-peligro.disabled:hover,
.btn.btn-peligro[disabled]:hover,
.btn.btn-peligro.disabled:focus,
.btn.btn-peligro[disabled]:focus,
.btn.btn-peligro.disabled.focus,
.btn.btn-peligro[disabled].focus,
.btn.btn-peligro.disabled:active,
.btn.btn-peligro[disabled]:active,
.btn.btn-peligro.disabled.active,
.btn.btn-peligro[disabled].active {
  background: var(--bs-btn-danger-hover-bg);
  border-color: var(--bs-btn-danger-hover-border-color);
  color: var(--bs-btn-danger-hover-color);
}

.btn.btn-peligro.disabled:hover,
.btn.btn-peligro[disabled]:hover,
.btn.btn-peligro.disabled:hover:hover,
.btn.btn-peligro[disabled]:hover:hover,
.btn.btn-peligro.disabled:focus:hover,
.btn.btn-peligro[disabled]:focus:hover,
.btn.btn-peligro.disabled.focus:hover,
.btn.btn-peligro[disabled].focus:hover,
.btn.btn-peligro.disabled:active:hover,
.btn.btn-peligro[disabled]:active:hover,
.btn.btn-peligro.disabled.active:hover,
.btn.btn-peligro[disabled].active:hover {
  -webkit-transform: none;
  transform: none;
}

.btn.btn-peligro:hover,
.btn.btn-peligro:focus,
.btn.btn-peligro.focus,
.btn.btn-peligro:active,
.btn.btn-peligro.active,
.btn.btn-peligro:target {
  background: var(--bs-btn-danger-hover-bg);
  border-color: var(--bs-btn-danger-active-border-color);
  color: var(--bs-btn-danger-hover-color);
}

#app > div {
  margin-top: 60px;
}

.btn.btn-outline-dark,
.btn.btn-outline-dark-hover-shadow {
  border: solid 1px var(--custom-text-dark);
  color: var(--custom-text-dark);
  background-color: var(--bg-custom-white);
}

.btn.btn-outline-dark:hover {
  color: var(--custom-text-white) !important;
  background-color: var(--bg-custom-secondary) !important;
}

.btn.btn-outline-dark-hover-shadow:hover {
  box-shadow: rgba(0, 0, 0, 0.25) -3px 3px 0px !important;
}

.btn.btn-outline-danger {
  border: solid 1px var(--custom-text-danger);
  color: var(--custom-text-danger);
  background-color: var(--bg-custom-white);
}

.btn.btn-outline-danger:hover {
  color: var(--custom-text-white) !important;
  background-color: var(--bg-custom-danger) !important;
}

/* Estilos menu */
.nav-item a {
  color: var(--custom-text-dark-gray);
}

.nav-pills li.active a,
.nav-pills li.active a:hover,
.nav-pills li.active a:focus {
  color: var(--custom-text-white);
  background-color: var(--bg-custom-primary);
}

.nav-pills li a {
  background-color: #e9e9e9;
  color: black;
}

.nav-pills li a:hover,
.nav-pills li a:focus {
  color: var(--custom-text-white);
  background-color: var(--bg-custom-primary);
}

.nav-item.current {
  background-color: var(--bg-custom-primary);
  color: var(--custom-text-dark);
}

.items-bottom-menu {
  position: absolute;
  width: 96%;
  bottom: 100px;
}

.items-top-menu {
  position: absolute;
  width: 96%;
  top: 5px;
}

.nav-item.current-link {
  background-color: var(--bg-custom-primary);
  border-radius: 30px;
}

.nav-item.current-link a {
  color: var(--custom-text-white);
}

.bar-support {
  background-color: var(--bg-custom-primary);
  height: 30px;
  position: fixed;
  bottom: 0px;
  z-index: 1150;
  width: 100vw;
  left: 0px;
}

.bar-support span {
  color: var(--custom-text-white);
}

.bar-support .bg-white i {
  color: var(--custom-text-dark);
}

/* Estilos types application welcome */
.line-welcome-form {
  border: 1px solid var(--bg-custom-primary);
  opacity: 1 !important;
}

/* Estilos types application Ci */
.borde-derecha {
  right: 55px;
}

.line-ci-form {
  height: 0.5px;
  background-color: var(--bg-custom-hr);
  margin-top: 0.5rem !important;
  border: none !important;
}

div.input-select {
  border-radius: 30px;
  height: 30px;
}

.input-select .css26l3qy-menu {
  z-index: 10 !important;
}

.input-group .form-control {
  z-index: 0 !important;
}

/*estilos para el configuration ci*/
.dimensiones {
  height: 80% !important;
}

.bg-quiestion {
  background-color: var(--bg-custom-primary);
}

.loader-form {
  border: 6px solid var(--custom-text-white);
  border-top: 6px solid var(--bg-custom-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: spinner-form 2s linear infinite;
  animation: spinner-form 2s linear infinite;
}

.line-vertical .vr {
  background-color: var(--bg-custom-secondary);
  width: 1px;
}

.line-vertical-primary .vr {
  background-color: var(--bg-custom-primary);
  width: 1px;
}

.autenticate .success {
  color: var(--bg-custom-success);
}

.bg-success {
  background-color: var(--bg-custom-success) !important;
}

.bg-danger {
  background-color: var(--bg-custom-danger) !important;
  color: #ffffff;
}

.bg-warning {
  background-color: rgba(223, 184, 28, 0.85);
  color: #ffffff;
}

.border-radius {
  border-radius: 15px !important;
}

.autenticate .danger {
  color: var(--bg-custom-danger);
}

.text-danger {
  color: var(--bg-custom-danger);
}

@-webkit-keyframes spinner-form {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner-form {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

/* Estilos modal */
.modal-footer {
  padding: 1rem !important;
}

/* Estilos input Yes or No */
.custom-control-input ~ .custom-control-label::before {
  color: #fff;
  background-color: var(--bg-custom-success);
  height: 15px;
  width: 30px;
  border-radius: 50px;
  border: none;
}

.custom-control-input ~ .custom-control-label::after {
  height: 15px;
  width: 15px;
}

.custom-control-input ~ .custom-control-label::before {
  color: #fff;
  background-color: var(--bg-custom-danger);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: var(--bg-custom-success);
}

@media screen and (max-height: 712px) {
  .items-top-menu {
    height: calc(100vh - 330px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .items-top-menu::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 2000px) {
  .img-left-login {
    width: 50% !important;
  }
}

/* Estilos sidebar */
.nav-link {
  color: var(--custom-text-white);
}

/* Estilos control de versiones */
.public-private,
.verification {
  background-color: var(--bg-custom-success);
  border-radius: 10px;
  color: var(--custom-text-white);
  padding: 5px 10px;
  width: fit-content;
}

.incomplete-integration {
  background-color: var(--bg-custom-primary);
  border-radius: 10px;
  color: var(--custom-text-dark);
  padding: 5px 10px;
  width: fit-content;
}

.button-descargar {
  background-color: var(--bg-custom-primary);
  border-radius: 10px !important;
  color: var(--custom-text-dark);
  padding: 2px 10px;
  width: fit-content;
}

.public-private-danger {
  background-color: var(--bg-custom-danger);
}

.verification-danger {
  background-color: var(--bg-custom-danger);
}

.download-server {
  background-color: var(--bg-custom-primary);
  border-radius: 10px;
  color: black;
  padding: 5px 10px;
  width: fit-content;
}

.download-server a {
  color: black;
}

.line-welcome-form-dark {
  height: 0.5px;
  background-color: var(--bg-custom-hr);
  margin-top: 0.5rem !important;
  border: none !important;
}

.height-view-render {
  height: calc(100vh - 80px) !important;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .height-view-render {
    height: calc(100vh - 80px) !important;
  }
}

@media screen and (max-width: 375px) {
  .height-view-render {
    height: calc(100vh + 80px) !important;
  }
}

@media screen and (max-width: 280px) {
  .height-view-render {
    height: calc(100vh + 300px) !important;
  }
}

.drop-tarjeta {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.toggle-form.react-toggle--checked .react-toggle-track {
  background-color: var(--bg-custom-success) !important;
}

.toggle-form.toggle-form-primary:not(.react-toggle--checked)
  .react-toggle-track {
  background-color: var(--bg-custom-primary) !important;
}

.toggle-form .react-toggle-track {
  background-color: var(--bg-custom-danger) !important;
}

.toggle-form .react-toggle-thumb {
  border-color: transparent;
}

/* Estilos componentes */
.table-th-primary tr th,
.bg-th-table {
  background-color: var(--bg-custom-primary) !important;
  color: var(--custom-text-white) !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: var(--custom-text-dark);
  background-color: var(--bg-custom-primary);
  border-color: var(--bg-custom-primary);
}

.table-pagination .pagination {
  margin: 5px 0;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: var(--bg-custom-primary);
  border-color: var(--bg-custom-primary);
  color: var(--custom-text-dark);
}

.toggle-form .bootstrap-switch {
  height: 24px;
  width: 51px !important;
  border-radius: 30px;
}

.toggle-form .bootstrap-switch span,
.toggle-form .bootstrap-switch div {
  height: 24px;
}

.toggle-form .bootstrap-switch .bootstrap-switch-handle-on,
.toggle-form .bootstrap-switch .bootstrap-switch-handle-off,
.toggle-form .bootstrap-switch .bootstrap-switch-label {
  width: 22px !important;
  padding: 0px 8px;
}

.toggle-form .bootstrap-switch .bootstrap-switch-handle-on {
  background-color: var(--bg-custom-success) !important;
  color: var(--custom-text-white);
}

.toggle-form .bootstrap-switch .bootstrap-switch-handle-off {
  background-color: var(--bg-custom-danger);
  color: var(--custom-text-white);
}

/* Estilos jerarquia*/
.bg-header-modal {
  background-color: var(--bg-custom-secondary);
}

.bg-header-modal h5,
.bg-header-modal button {
  color: var(--custom-text-white) !important;
}

/* btn go back */
.btn-go-back {
  width: 35px;
  font-size: 20px;
}

/* Estilos etapas ci */
.customselectpicker {
  height: 30px;
}

.customselectpicker button {
  border-radius: 30px;
  height: 30px;
  padding: 3px 12px 3px 12px;
}

.table .thead-dark th {
  border: 1px solid gainsboro !important;
}

/* Estilos tasa de exito de despliegue */
.card .card-header {
  background-color: var(--bg-custom-white) 0;
  /* El 0 es para la opacidad */
}

select.form-control {
  border-radius: 30px;
  height: 30px;
}

/* Canvas login */
.bs-canvas-top {
  left: 0;
  z-index: 1110;
  transition: transform 0.4s ease-out;
  -webkit-transition: transform 0.4s ease-out;
  -moz-transition: transform 0.4s ease-out;
  -ms-transition: transform 0.4s ease-out;
  left: 0;
  background-color: var(--bg-custom-primary);
}

.dropleft::before {
  border: none;
}

.transform-canvas {
  transform: translate(0%, -100%);
}

.btn-open-close-canvas {
  right: 4%;
}

.bs-canvas-top a {
  bottom: -24px;
}

/* Go to component */
.go-to-component:hover {
  background: var(--bs-btn-primary-bg);
  border-color: var(--bs-btn-primary-border-color);
  color: var(--bs-btn-primary-color);
  border-radius: 10px;
  cursor: pointer;
  text-decoration: underline;
}

/* Modal Component information QA */
.bg-header-modal-primary {
  background-color: var(--bg-custom-primary);
  color: var(--custom-text-white);
}

.bg-header-modal-primary button span {
  color: var(--custom-text-white);
}

.cards-modal-extern-quality .title-distribution {
  font-size: 14px;
}

.cards-modal-extern-quality .value-distribution {
  font-size: 20px;
}

.bg-danger-card {
  background-color: var(--bg-custom-danger);
  color: var(--custom-text-white);
}

.bg-orange-card {
  background-color: var(--bg-custom-orange);
  color: var(--custom-text-dark);
}

.bg-warning-card {
  background-color: var(--bg-custom-warning);
  color: var(--custom-text-dark);
}

.cursor-pointer {
  cursor: pointer;
}

.modal-open .modal {
  overflow: hidden;
  z-index: 2050;
}

/* Animate collapse preguntas frecuentes */
@keyframes backInDown {
  0% {
    transform: translateY(-1200px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    transform: translateY(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.backInDown {
  animation-name: backInDown;
}

.animate__animated {
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.dropdown-toggle.no-border-after-dropdown::after {
  border: none;
}

.dropdown-toggle.no-border-before-dropdown::before {
  border: none;
}

.dropdown-toggle.no-border-before-dropdown {
  display: inline-table;
}

/* Estilos tooltop ayudas o help */
.tooltip-container {
  position: relative;
}

.tooltip-module {
  background-color: var(--bg-custom-primary);
  border-radius: 100%;
  color: var(--custom-text-white);
  box-shadow: 0 0.5rem 1rem var(--bg-custom-gray);
  cursor: pointer;
}

.tooltip-module-title {
  width: 30px;
  height: 30px;
  padding: 5px 12px;
  font-size: 15px;
}

.content-tooltip {
  opacity: 0;
  z-index: -1;
  position: absolute;
  background: var(--bg-custom-white);
  border-radius: 10px;
  transform: translateY(20px);
  transition: all 0.4s ease;
  border: var(--bg-custom-primary) 3px solid;
  max-width: 250px;
  width: calc(97vw - 250px);
  margin-bottom: 0px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.content-tooltip p {
  max-height: 200px;
  overflow-y: auto;
}

@media screen and (max-width: 368px) {
  .content-tooltip {
    width: 45vw;
  }
}

.content-tooltip-title {
  top: -7px;
  left: 50px;
  padding: 1rem;
}

.triangle-tooltip {
  position: absolute;
  border-width: 10px 10px 10px 10px;
  border-style: solid;
  border-color: transparent var(--bg-custom-primary) transparent transparent;
  z-index: 1;
  transform: translateY(20px);
}

.triangle-tooltip-title {
  top: -10px;
  left: -23px;
}

.content-circle-question.active-tooltip ~ .content-tooltip {
  opacity: 1;
  transform: translateY(-2px);
  z-index: 498;
}

/* Estilos tooltipp para input right */
.tooltip-module-input {
  width: 20px;
  height: 20px;
}

.content-tooltip-input {
  top: -10px;
  left: 40px;
  padding: 1rem;
}

.triangle-tooltip-input {
  top: -10px;
  left: -23px;
}

.content-tooltip-input-left {
  top: -10px;
  right: 35px;
  left: unset;
  max-width: 200px;
}

.triangle-tooltip-input-left {
  border-color: transparent transparent transparent var(--bg-custom-primary);
  top: -10px;
  right: -22px;
  left: unset;
}

@media screen and (max-width: 800px) {
  .content-tooltip-title {
    top: 50px;
    left: 0px;
  }

  .triangle-tooltip-title {
    border-color: transparent transparent var(--bg-custom-primary) transparent;
    top: -42px;
    left: 2px;
  }

  .content-tooltip-input-left {
    top: 38px;
    right: -5px;
  }

  .triangle-tooltip-input-left {
    border-color: transparent transparent var(--bg-custom-primary) transparent;
    top: -42px;
    right: 2px;
  }
}

@media screen and (max-width: 600px) {
  .content-tooltip-title {
    left: -115px;
  }

  .content-tooltip-input {
    top: 38px !important;
    left: -110px !important;
  }

  .triangle-tooltip-title {
    left: 115px;
  }

  .content-tooltip {
    width: unset;
  }

  .triangle-tooltip-input,
  .content-tooltip-left {
    border-color: transparent transparent var(--bg-custom-primary) transparent;
    top: -42px;
    left: 105px;
  }
}

@media screen and (max-width: 767px) {
  .container-app {
    padding: 80px 30px 100px 30px;
  }
}

/* Estilos card dashboard */
.card-height-dash {
  height: 400px;
}

.card {
  border-radius: 15px !important;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}

.btn {
  border-radius: 5px;
  transition: all 0.1s ease;
}

#toast-container .toast {
  box-shadow: none !important;
}

#react-burger-menu-btn:focus {
  outline: 0 !important;
}

label {
  margin-bottom: 5px;
  color: var(--custom-text-dark);
  font-weight: 400;
}

.select-report,
.height-input {
  height: 30px !important;
}

.modal-angularjs-scroll .modal-body {
  max-height: calc(100vh - 200px);
  overflow: auto;
}

/* Dropdowns pagetop */
.top-dropdown-menu {
  width: 316px;
  left: auto;
  right: -47px;
  top: 26px;
}

.top-dropdown-menu ::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

.top-dropdown-menu ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.top-dropdown-menu ::-webkit-scrollbar-track {
  background: #fff;
}

.top-dropdown-menu.profile-dropdown {
  width: 230px;
  top: 45px;
  right: -10px;
}

.top-dropdown-menu.profile-dropdown a {
  text-align: left;
  border: none;
  text-decoration: none;
  color: #7d7d7d;
  padding: 4px 16px 4px 20px;
}

.top-dropdown-menu.profile-dropdown a.signout {
  border-top: 1px solid #ffffff;
}

.top-dropdown-menu.profile-dropdown a i {
  margin-right: 10px;
}

.top-dropdown-menu.profile-dropdown a:hover {
  background: #f4fcff;
}

@media (max-width: 415px) {
  .top-dropdown-menu {
    right: -81px;
  }
}

.custom-rounded-modal .modal-content {
  border-radius: 25px;
}

.detail-login-register {
  background-color: rgba(0, 0, 0, 0.5);
  width: 90%;
  top: 45%;
  left: 49%;
  transform: translate(-50%, -50%);
}

.detail-tour-login-register {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 2%;
  left: 49%;
  width: 90%;
  transform: translate(-50%, -50%);
}

.modal-config-component-current-user .modal-dialog {
  min-width: 75vw !important;
}

.scroll-form-component-current-user {
  min-height: calc(100vh - 270px);
  max-height: calc(100vh - 270px);
  overflow-y: auto;
}

@media screen and (max-width: 767px) {
  #modal-config-quality-server .modal-dialog {
    min-width: 90vw !important;
  }
}

.border-btn-azure {
  border: 1px solid #dadce0 !important;
  height: 30px !important;
}

.dropdown-toggle.not-arrow::after {
  content: unset;
}

.text-network {
  border-radius: 10px;
  padding: 5px 10px;
  width: fit-content;
}

.text-netwrok-public {
  color: var(--custom-text-netwrok-public);
  border: 1px solid var(--custom-text-netwrok-public);
}

.text-netwrok-private {
  color: var(--custom-text-netwrok-private);
  border: 1px solid var(--custom-text-netwrok-private);
}

.container-progress-home .box .circle {
  width: 80px;
  height: 80px;
  background: conic-gradient(
    from 0deg,
    var(--background-progress-bar-home) 0%,
    var(--background-progress-bar-home) 0% var(--percentage-progress-home),
    #333 var(--percentage-progress-home),
    #333 100%
  );
  border-radius: 50%;
}

.container-progress-home .box .circle::before {
  content: "";
  position: absolute;
  inset: 10px;
  background-color: var(--bg-custom-white);
  border-radius: 50%;
}

.container-progress-home .box h5 {
  color: var(--background-progress-bar-home);
  top: 40%;
}

.img-blue-planet-history {
  width: 180px;
  position: absolute;
  bottom: -1px;
  left: -1px;
  border-radius: 25px;
}

.img-apoque-planet-history {
  width: 180px;
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-radius: 25px;
}

.btn-home {
  background-color: var(--bg-custom-secondary-color) !important;
  border-color: var(--bg-custom-secondary-color) !important;
  border-radius: 10px;
}

/* Estilos anteriores a la modificacion inicial */

#btn-info-qaext {
  min-width: 210px;
  cursor: pointer;
}

.dropdown button.btn.btn-default.dropdown-toggle {
  color: #666666;
  border: 1px solid #d6d6d6;
  background-color: transparent;
  border-radius: 30px;
}

.dropdown button.btn.btn-default.dropdown-toggle:focus,
.dropdown button.btn.btn-default.dropdown-toggle:active {
  background-color: #ffffff;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.bootstrap-select button.btn-default:focus {
  color: #ffffff;
}

.bootstrap-select .btn {
  transition: none;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: transparent;
}

body {
  scrollbar-face-color: #d9d9d9;
  scrollbar-track-color: transparent;
}

html {
  position: relative;
  min-width: 320px;
}

html,
body {
  height: calc(100vh - 100px);
  min-width: 320px;
}

body {
  font: 14px/16px "Poppins", "Arial", sans-serif;
  color: #666666;
  background-color: var(--bg-custom-white);
}

body.blur-theme::before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*background: url("/images/dashboard/blur-bg.jpg") no-repeat center center;*/
  background-size: cover;
  will-change: transform;
  z-index: -1;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    overflow: hidden;
    height: 100%;
  }

  body {
    overflow: auto;
    height: 100%;
  }
}

a {
  transition: color 0.5s ease;
  outline: 0 !important;
}

.full-invisible {
  visibility: hidden !important;
}

.full-invisible * {
  visibility: hidden !important;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #000000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#preloader > div {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e85656;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}

#preloader > div:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #209e91;
  -webkit-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}

#preloader > div:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #dfb81c;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}

.table {
  margin-bottom: 0px;
}

.table > thead > tr > th:first-child {
  text-align: center;
}

.table > thead > tr > th:last-child {
  padding-right: 16px;
}

.table > tbody > tr > tr:first-child {
  padding-top: 1px;
}

.table > tbody > tr > td:first-child {
  text-align: center;
}

.add-row-editable-table {
  margin-bottom: 10px;
}

.add-row-editable-table + table {
  margin-bottom: 5px;
}

.select-page-size-wrap {
  width: 150px;
}

.table .header-row th {
  vertical-align: middle;
  padding: 0 8px;
}

tr.editable-row input.form-control {
  vertical-align: middle;
}

.select-td .editable-select {
  margin-bottom: 1px;
}

.st-sort-ascent,
.st-sort-descent {
  position: relative;
}

.st-sort-ascent:after,
.st-sort-descent:after {
  width: 0;
  height: 0;
  border-bottom: 4px solid #666666;
  border-top: 4px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-bottom: 2px;
}

.st-sort-descent:after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  margin-bottom: -2px;
}

.sortable th {
  cursor: pointer;
}

.sortable th:after {
  content: "";
  display: inline-block;
  width: 8px;
  margin-left: 8px;
}

input.search-input {
  margin-left: -8px;
  padding-left: 8px;
  height: 34px;
}

.table .pagination {
  margin: 4px 0 -12px 0;
}

.table .pagination a {
  cursor: pointer;
}

.pagination > li > a,
.pagination > li > span {
  background: transparent;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.table > tbody > tr.no-top-border:first-child > td {
  border-top: none;
}

.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid gainsboro;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}

.table > tbody > tr.primary > td {
  background-color: rgba(32, 158, 145, 0.7);
  color: #ffffff;
  border: none;
}

.table > tbody > tr.success > td {
  background-color: rgba(144, 185, 0, 0.7);
  color: #ffffff;
  border: none;
}

.table > tbody > tr.warning > td {
  background-color: rgba(223, 184, 28, 0.7);
  color: #ffffff;
  border: none;
}

.table > tbody > tr.danger > td {
  background-color: rgba(232, 86, 86, 0.7);
  color: #ffffff;
  border: none;
}

.table > tbody > tr.info > td {
  background-color: rgba(45, 172, 209, 0.7);
  color: #ffffff;
  border: none;
}

th {
  font-weight: 400;
}

.table > tbody > tr > th {
  border: none;
}

.table-striped > tbody > tr > td {
  border: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #666666;
  border-color: #d6d6d6;
}

.pagination > li:first-of-type > a,
.pagination > li:first-of-type > span {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination > li:last-of-type > a,
.pagination > li:last-of-type > span {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: rgba(0, 0, 0, 0.2);
  color: #666666;
}

.table-responsive {
  margin-top: 10px;
}

.admin-chart {
  width: 100%;
  height: 310px;
  font-size: 11px;
}

.admin-chart-two {
  height: 165px;
}

#pieChart {
  max-width: 1120px;
}

.ct-area {
  fill-opacity: 0.5;
}

.ct-label {
  color: #666666;
  opacity: 0.9;
  fill: #666666;
}

.ct-chart .ct-label {
  font-size: 1em;
}

.ct-chart svg {
  width: 100%;
  display: block;
}

.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut,
.ct-series-b .ct-slice-pie {
  stroke: #90b900;
}

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-area {
  fill: #90b900;
}

.ct-series-c .ct-bar,
.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-slice-donut,
.ct-series-c .ct-slice-pie {
  stroke: #e85656;
}

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-area {
  fill: #e85656;
}

.ct-series-d .ct-bar,
.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-slice-donut,
.ct-series-d .ct-slice-pie {
  stroke: #dfb81c;
}

.ct-series-d .ct-slice-pie,
.ct-series-d .ct-area {
  fill: #dfb81c;
}

.ct-series-e .ct-bar,
.ct-series-e .ct-line,
.ct-series-e .ct-point,
.ct-series-e .ct-slice-donut,
.ct-series-e .ct-slice-pie {
  stroke: #2dacd1;
}

.ct-series-e .ct-slice-pie,
.ct-series-e .ct-area {
  fill: #2dacd1;
}

@media screen and (min-width: 992px) {
  .row.morris-up > div {
    margin-top: -434px;
  }
}

.pie-chart-panel {
  padding: 0;
}

.chart-panel {
  height: 495px;
}

#filterChart {
  width: 96%;
  font-size: 11px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.chartist h5 {
  font-weight: 400;
}

.important {
  color: #dfb81c;
}

.line {
  height: 1px;
  background-color: #cbcbcb;
  opacity: 0.3;
}

.collapsed {
  width: 0;
}

.form-control {
  color: #666666;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  color: #666666;
  opacity: 0.7;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #666666;
  opacity: 0.7;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #666666;
  opacity: 0.7;
}

.form-control:-ms-input-placeholder {
  color: #666666;
  opacity: 0.7;
}

.form-control:focus {
  box-shadow: none;
  border-color: #4db1a7;
  background: #ffffff;
}

select.form-control {
  padding-left: 8px;
}

.bootstrap-switch {
  border-radius: 5px;
  border: 1px solid #ffffff;
  transition: border-color ease-in-out 0.7s, box-shadow ease-in-out 0.7s;
}

.bootstrap-switch:focus {
  outline: none;
}

.bootstrap-switch.bootstrap-switch-off {
  border-color: #d6d6d6;
}

.bootstrap-switch.bootstrap-switch-focused {
  box-shadow: none;
}

.bootstrap-switch.bootstrap-switch-focused.bootstrap-switch-off {
  border-color: #d6d6d6;
}

.bootstrap-switch .bootstrap-switch-container {
  border-radius: 0;
}

.bootstrap-switch .bootstrap-switch-container:focus {
  outline: none;
}

.bootstrap-switch .bootstrap-switch-handle-on {
  border-radius: 0;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
  background: #ffffff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
  background: #90b900;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
  background: #dfb81c;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
  background: #e85656;
}

.bootstrap-switch .bootstrap-switch-handle-off {
  border-radius: 0;
}

.bootstrap-switch .bootstrap-switch-label {
  background: transparent;
}

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  transition: margin-left 0.2s;
}

.input-group {
  width: 100%;
  margin-bottom: 15px;
}

.input-group > span {
  border-radius: 0;
}

.cut-with-dots {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.bootstrap-select .btn-default:focus {
  color: #666666;
}

.dropdown-menu {
  border-radius: 5px;
}

.progress {
  background: rgba(0, 0, 0, 0.07);
}

.toast-success {
  background-color: #90b900;
}

.toast-error {
  background-color: #e85656;
}

.toast-info {
  background-color: #2dacd1;
}

.toast-warning {
  background-color: #dfb81c;
}

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  margin: 5px auto;
}

.modal .modal-dialog .form-control::-webkit-input-placeholder {
  color: #7d7d7d;
  opacity: 0.9;
}

.modal .modal-dialog .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #7d7d7d;
  opacity: 0.9;
}

.modal .modal-dialog .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #7d7d7d;
  opacity: 0.9;
}

.modal .modal-dialog .form-control:-ms-input-placeholder {
  color: #7d7d7d;
  opacity: 0.9;
}

@media (max-width: 991px) {
  .toastr-radio-setup {
    margin-left: 22px;
  }
}

.toast-title {
  font-weight: 400;
}

.toast-message {
  font-weight: 300;
  font-size: 13px;
}

#toast-container.toast-top-center .toast,
#toast-container.toast-bottom-center .toast {
  margin-bottom: 5px;
}

.close {
  opacity: 1;
  box-shadow: none;
  outline: none !important;
}

.close:hover,
.close:focus,
.close:active {
  opacity: 1;
}

.profile-page .form-group input {
  font-weight: 300;
}

.panel.tabs-panel .panel-body {
  padding: 0;
}

.panel.tabs-panel .dropdown-menu {
  min-width: 132px;
  top: auto;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.panel.tabs-panel.with-scroll .panel-body {
  height: 100%;
}

.jstree-default a.jstree-clicked,
.jstree-default a.jstree-hovered {
  background-color: rgba(0, 0, 0, 0.25);
}

.jstree-default a.jstree-anchor:hover,
.jstree-default a.jstree-wholerow:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", "Arial", sans-serif;
  width: 100%;
  margin-top: 0;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

.typography-document-samples p {
  margin: 0;
}

.typography-document-samples .typography-widget {
  height: 100%;
}

.typography-document-samples .typography-widget .panel {
  height: 620px;
}

.typography-document-samples .typography-widget .panel-title {
  text-align: center;
  width: 100%;
}

.typography-document-samples .typography-widget .panel.with-scroll .panel-body {
  height: calc(100% - 45px);
}

.typography-document-samples .typography-widget .panel-content {
  padding: 15px 22px 5px 22px;
}

p {
  margin-bottom: 12px;
  font-family: "Poppins", "Arial", sans-serif;
  font-size: 14px;
}

.panel {
  color: #666666;
  background-color: #ffffff;
  border-radius: 15px;
  position: relative;
  margin-bottom: 24px;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
  border-color: #d6d6d6;
}

.panel ::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

.panel ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.panel ::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.7);
}

.panel body {
  scrollbar-face-color: rgba(0, 0, 0, 0.6);
  scrollbar-track-color: rgba(255, 255, 255, 0.7);
}

.panel.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.panel.small-panel {
  height: 114px;
}

.panel.medium-panel {
  height: 430px;
}

.panel.xxmedium-panel {
  height: 100%;
  max-height: 1000px;
}

.panel.with-scroll .panel-body {
  height: calc(100% - 44px);
  overflow-y: auto;
}

.panel > .panel-body {
  height: 100%;
}

.panel > .panel-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.panel > .panel-footer {
  color: #666666;
}

.panel-heading,
.panel-footer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.12);
  font-size: 16px;
  padding: 14px 22px;
}

.panel-title {
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.9;
  color: #000;
  font-size: 14px;
}

.panel-group .panel .panel-heading {
  border-bottom: 1px solid #ddd;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.tab-content {
  padding: 15px 15px 5px 15px;
  background: transparent;
  color: #666666;
}

.tab-content .tab-pane p {
  color: #666666;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
}

.back-top {
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 9999;
  display: none;
  text-decoration: none;
  right: 74px;
  bottom: 20px !important;
  font-size: 40px;
  text-align: center;
  opacity: 0.5;
  background-color: white;
  color: rgb(255, 197, 22);
  border-radius: 50%;
}

.back-top:hover {
  opacity: 1;
}

h1.al-title {
  font-weight: 700;
  color: #000;
  float: left;
  width: auto;
  margin: 0;
  padding: 0;
  font-size: 24px;
  text-transform: capitalize;
  opacity: 0.9;
}

.al-breadcrumb {
  background: none;
  color: #666666;
  padding: 0;
  margin: 0;
  float: right;
  padding-top: 11px;
}

.al-breadcrumb li {
  font-size: 15px;
  font-weight: 400;
}

.al-breadcrumb a {
  font-size: 15px;
  font-weight: 400;
  color: var(--bg-custom-primary);
}

.go-back {
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 9999;
  text-decoration: none;
  right: 74px;
  bottom: 20px !important;
  font-size: 40px;
  text-align: center;
  opacity: 0.4;
  color: #209e91;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  line-height: 28px;
}

.go-back:hover {
  opacity: 0.8;
}

.node {
  cursor: pointer;
}

.node:hover {
  stroke: #000;
  stroke-width: 1.5px;
}

.node--root {
  stroke: #777;
  stroke-width: 2px;
}

.node--leaf {
  fill: white;
  stroke: #777;
  stroke-width: 1px;
}

.node--root,
.node--leaf {
  pointer-events: none;
}

.page-top {
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  min-width: 320px;
}

.page-top.scrolled {
  background-color: rgba(0, 0, 0, 0.85);
}

.user-profile {
  float: right;
  min-width: 230px;
  margin-top: 10px;
}

.al-user-profile {
  margin-right: 12px;
  transition: all 0.15s ease-in-out;
  width: 25px;
  height: 25px;
}

.al-user-profile a {
  display: block;
}

@media screen and (max-width: 500px) {
  .page-top {
    padding: 0 20px;
  }
}

@media (max-width: 435px) {
  .user-profile {
    min-width: 136px;
  }
}

.profile-toggle-link {
  cursor: pointer;
  color: var(--custom-text-dark);
}

.widgets {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#amchart {
  width: 100%;
  height: 350px;
  margin-top: -20px;
}

#amChartMap {
  width: 100%;
  height: 315px;
}

/*New styles*/
label {
  font-family: "Poppins", "Arial", sans-serif;
  font-weight: normal;
}

g,
tspan {
  font-size: 11px;
  font-family: "Poppins", "Arial", sans-serif;
}

div {
  font-family: "Poppins", "Arial", sans-serif;
}

div.amcharts-chart-div a[style] {
  visibility: hidden;
}

div.amChartsDataSetSelector.amcharts-data-set-selector-div {
  font-size: 11px;
}

.table > tbody > tr > td {
  padding: 4px;
  vertical-align: inherit;
}

input::-webkit-input-placeholder {
  font-size: 14px;
}

fieldset > div {
  font-size: 11px;
  font-family: "Poppins", "Arial", sans-serif;
}

.glow-on-hover {
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  animation: buttonJump 1.8s infinite;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff5e00,
    #ffae00,
    #fffb00,
    #ffae00,
    #ffd000,
    #ff5e00
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(4px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 1;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes buttonJump {
  50% {
    transform: scale(0.94);
  }

  100% {
    transform: scale(1);
  }
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 5px !important;
}

.p-2 {
  padding: 10px !important;
}

.p-3 {
  padding: 15px !important;
}

.p-4 {
  padding: 20px !important;
}

.p-5 {
  padding: 25px !important;
}

.pt-1 {
  padding-top: 5px !important;
}

.pt-2 {
  padding-top: 10px !important;
}

.pt-3 {
  padding-top: 15px !important;
}

.pt-4 {
  padding-top: 20px !important;
}

.pt-5 {
  padding-top: 25px !important;
}

.pb-1 {
  padding-bottom: 5px !important;
}

.pb-2 {
  padding-bottom: 10px !important;
}

.pb-3 {
  padding-bottom: 15px !important;
}

.pb-4 {
  padding-bottom: 20px !important;
}

.pb-5 {
  padding-bottom: 25px !important;
}

.pl-1 {
  padding-left: 5px !important;
}

.pl-2 {
  padding-left: 10px !important;
}

.pl-3 {
  padding-left: 15px !important;
}

.pl-4 {
  padding-left: 20px !important;
}

.pl-5 {
  padding-left: 25px !important;
}

.pr-1 {
  padding-right: 5px !important;
}

.pr-2 {
  padding-right: 10px !important;
}

.pr-3 {
  padding-right: 15px !important;
}

.pr-4 {
  padding-right: 20px !important;
}

.pr-5 {
  padding-right: 25px !important;
}

.px-5 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

/* Card columns media queries */
.card-columns {
  column-count: 1;
}

@media (min-width: 768px) {
  /* Medium screens: 2 columns */
  .card-columns {
    column-count: 2;
  }
}

@media (min-width: 1200px) {
  /* Large screens: 3 columns */
  .card-columns {
    column-count: 3;
  }
}

.golden-radial {
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
}
